// Step 1: Import React
import React from "react";
import Layout from '../components/Layout';
import { graphql } from "gatsby"
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { Container } from 'reactstrap';

// Step 2: Define your component
const SuccessPage = () => {

  return (
    <main>
      <Layout>
        <title>Thanks for your order!</title>
            <Container className="siteStyl">
                <h2>Thanks for your order!</h2>
                <p>
                    We appreciate your business! <br />
                    If you have any questions, please email to&nbsp;
                    <a href="mailto:support@magicauthor.com">support@magicauthor.com</a>
                </p>
            </Container>
      </Layout>
    </main>
    )
}

// Step 3: Export your component
export default SuccessPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;